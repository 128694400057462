.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 50px;
}

.project-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-card img {
  width: 100%;
  height: 25%;
}

.project-card h5 {
  padding: 10px;
  font-size: 1.25rem;
  background-color: #333;
  color: #fff;
}

.project-card p,
.project-card ul {
  margin: 10px;
  padding: 0 10px;
}

.project-card a {
  display: block;
  padding: 10px;
  text-align: center;
  background-color: rgba(255, 147, 1, 1);
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  margin: 10px;
  transition: background-color 0.2s;
}

.project-card a:hover {
  background-color: rgba(200, 120, 0, 1);
}

/* Scrollable description block */
.project-description {
  max-height: 150px; /* Adjust the height as needed */
  overflow-y: auto;
  margin-bottom: 10px;
  border: 1px solid #ccc; /* Adds a light grey border */
  border-radius: 4px; /* Optional: Rounds the corners of the border */
  padding: 10px; /* Adds some spacing inside the box */
  background-color: #f9f9f9; /* Optional: Adds a background color */
}


/* Additional style for list items inside the description block */
.project-description li {
  list-style-type: disc;
  list-style-position: inside;
  margin-bottom: 5px;
}
